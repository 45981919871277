import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import subscribePlugin from "./plugins/pusher";
import axiosConfig from "./plugins/axios";
import VueSweetalert2 from "vue-sweetalert2";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import GAuth from "vue3-google-oauth2";
const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_APP_ID,
  scope: "email",
  prompt: "consent",
};
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/sass/app.scss";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

import DashboardLayout from "@/components/DashboardLayout.vue";
import EmptyLayout from "@/components/EmptyLayout.vue";
// Add or update axios base URL
import axios from "axios";

axios.defaults.baseURL = "https://conn.qandgo.app"; // e.g., 'http://localhost:8000'
const app = createApp(App);

app.component("default-layout", DashboardLayout);
app.component("empty-layout", EmptyLayout);
app.config.globalProperties.$hostname = process.env.VUE_APP_URL;
app
  .use(store)
  .use(router)
  .use(subscribePlugin)
  .use(axiosConfig)
  .use(VueSweetalert2)
  .use(VueLoading)
  .use(GAuth, gAuthOptions)
  .use(VueTelInput, { defaultCountry: "AU" });

store.$http = app.config.globalProperties.$http;

app.mount("#app");
